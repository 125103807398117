<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">课程合作管理</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">合作方列表</a>
                    <i>></i>
                    <a href="javascript:;" @click="init" class="cur-a">导出列表</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items: flex-start; ">
                    <div class="searchbox" style="margin-bottom:5px">
                        <div title="课程名称" class="searchboxItem ci-full">
                            <span class="itemLabel">课程名称:</span>
                            <el-input v-model="name" type="text" size="small" clearable placeholder="请输入课程名称" />
                        </div>
                        <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small"
                            tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
                            <el-table-column label="序号" align="center" type="index" width="100px"
                                :index="indexMethod" />
                            <el-table-column label="课程名称" align="center" prop="courseName" show-overflow-tooltip
                                min-width="100" />
                            <el-table-column label="导出人" align="center" prop="exportUser" show-overflow-tooltip
                                min-width="100" />
                            <el-table-column label="导出人账号" align="center" prop="adminName" show-overflow-tooltip
                                min-width="100" />
                            <el-table-column label="课程推送方向" align="center" prop="adminName" show-overflow-tooltip
                                min-width="100">
                                <template slot-scope="scope">
                                    {{ $setDictionary("PARTNER_COURSE_PAY", scope.row.partnerCoursePay) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="导出时间" align="center" prop="createTime" show-overflow-tooltip
                                min-width="100" />
                            <el-table-column label="操作" align="center" width="140px" fixed="right">
                                <div slot-scope="scope" class="flexcc">
                                    <a :href=scope.row.storageUrl class="downloadCourse">下载课程</a>
                                </div>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
        </div>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
    name: "Partner/partnerRecordList",
    components: {
        Empty,
        PageNum
    },
    mixins: [List],
    data() {
        return {
            createTime: "",
            partnerId: "",
            partnerName: "",
            DialogVisible: false,
            name: ""
        };
    },
    computed: {},
    created() {
        this.getTableHeight();
        this.init();
    },
    methods: {
        init() {
            const query = this.$route.query;
            this.createTime = query.createTime;
            this.partnerId = query.partnerId;
            this.partnerName = query.partnerName;
            if (this.partnerId) {
                this.getData();
            }
        },
        doCloseload() {
            this.centerDialogVisible = false;
            //this.clearSelection();
        },
        getData(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                partnerId: this.partnerId,
                name: this.name ? this.name : "",
            };
            this.doFetch({
                url: "/biz/chinahrt/export/listPage",
                params,
                pageNum
            });
        },
        getTableHeight(opDom = true, page = true) {
            let tHeight =
                window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
            if (opDom) {
                tHeight -= 40 + 40 + 0.675 * 16 + 1;
            }
            if (page) {
                tHeight -= 32;
            }
            this.tableHeight = tHeight;
        }
    },
    beforeRouteLeave: resetKeepAlive,
    watch: {
        $route: {
            handler: function (val, oldVal) {
                if (val.query.refrsh == true) {
                    this.getData(-1);
                }
                if (oldVal.query.stu == "add") {
                    (this.adminName = ""),
                        (this.compName = ""),
                        (this.fullname = ""),
                        (this.mobile = ""),
                        (this.realRoleName = ""),
                        (this.roleId = ""),
                        (this.state = ""),
                        (this.cityId = ""),
                        (this.trainTypeId = ""),
                        (this.pageNum = 1),
                        this.getData(-1);
                }
            },
            // 深度观察监听
            deep: true
        }
    }
};
</script>
<style lang="less">
.addlist {
    .el-icon-plus:before {
        content: "\e6d9";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.table-switch {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-switch__core {
        width: 40px !important;
    }

    .el-switch__core:after {
        background-color: #fff;
    }

    span {
        line-height: 20px;
        margin-left: 5px;
    }
}

.operationControl>div>div>span {
    min-width: 6rem;
}

.itemLabel {
    display: inline-block;
    width: 3rem;
}

.downloadCourse {
    color: #409EFF;
}

.downloadCourse:hover {
    color: #66b1ff;
    cursor: pointer;
}
</style>
<style lang="less" scoped></style>
